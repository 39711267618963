'use client';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import { isIOS } from '@react-aria/utils';

export default function GsapInit() {
  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (!isIOS()) {
    ScrollTrigger.normalizeScroll({allowNestedScroll: true});
    }
  })

  return <></>;
}
