'use client';

import { useEffect } from 'react';
import * as CookieConsent from 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import getConfig from '@/data/cookie-consent-settings';

const ResetCookieConsent = () => {
  CookieConsent.reset(true);
  CookieConsent.run(getConfig());
};

const CookieConsentComponent = () => {
  useEffect(() => {
    CookieConsent.run(getConfig());
  }, []);

  return (
    <div>
      <button type="button" onClick={CookieConsent.showPreferences}>
        Nastavit cookies
      </button>
      <button type="button" onClick={ResetCookieConsent}>
        Resetovat nastavení cookies
      </button>
    </div>
  );
};

export default CookieConsentComponent;
