import type { CookieConsentConfig } from 'vanilla-cookieconsent';
import {} from '@next/third-parties/google'
import { gtag } from '@/utils/gtagPush';

const getConfig = () => {
  const config: CookieConsentConfig = {
    // root: 'body',
    autoShow: true,
    disablePageInteraction: false,
    hideFromBots: false,
    mode: 'opt-in',
    revision: 0,

    cookie: {
      name: 'cc_cookie',
      domain: location.hostname,
      path: '/',
      sameSite: "Lax",
      expiresAfterDays: 365,
    },

    /**
     * Callback functions
     */
    onFirstConsent: ({ cookie }) => {
      console.log('onFirstConsent fired', cookie);
    },

    onConsent: ({ cookie }) => {
      console.log('consent fired', cookie);
    },

    onChange: ({ changedCategories, changedServices }) => {
      console.log('onChange fired!', changedCategories, changedServices);
    },

    onModalReady: ({ modalName }) => {
      console.log('ready:', modalName);
    },

    onModalShow: ({ modalName }) => {
      console.log('visible:', modalName);
    },

    onModalHide: ({ modalName }) => {
      console.log('hidden:', modalName);
    },

    // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
    guiOptions: {
      consentModal: {
        layout: 'box inline',
        position: 'bottom left',
        equalWeightButtons: false,
        flipButtons: true,
      },
      preferencesModal: {
        layout: 'box',
        equalWeightButtons: false,
        flipButtons: true,
      },
    },

    categories: {
      necessary: {
        enabled: true, // this category is enabled by default
        readOnly: true, // this category cannot be disabled

        services: {
          mux: {
            label: 'Mux'
          },
        }
      },
      analytics: {
        enabled: false,
        autoClear: {
          cookies: [
            {
              name: /^_ga/, // regex: match all cookies starting with '_ga'
            }
          ],
        },

        services: {
          ga: {
            label: 'Google Analytics',
            onAccept: () => {
              gtag('consent', 'update', {
                ad_storage: 'granted',
                analytics_storage: 'granted'
              });
            },
            onReject: () => {
              gtag('consent', 'update', {
                ad_storage: 'denied',
                analytics_storage: 'denied'
              });
            },
          },
        },
      },
      ads: {
        enabled: false,
        autoClear: {
          cookies: [
            {
              name: /^_gcl/,
            }
          ],
        },

        services: {
          ga: {
            label: 'Google Ads',
            onAccept: () => {
              gtag('consent', 'update', {
                ad_user_data: 'granted',
                ad_personalization: 'granted',
              });
            },
            onReject: () => {
              gtag('consent', 'update', {
                ad_user_data: 'denied',
                ad_personalization: 'denied',
              });
            },
          },
        },
      },
    },

    language: {
      default: 'cs',
      translations: {
        cs: {
          consentModal: {
            title: 'Používáme cookies 🍪',
            description:
              'Komu by nechutnaly cookies? Díky cookies vám můžeme nabídnout ještě lepší služby na webu i mimo něj. Budete péct s námi?',
            acceptAllBtn: 'Chci všechny',
            // acceptNecessaryBtn: 'Jen nezbytné',
            showPreferencesBtn: 'Přesně vybrat',
            // closeIconLabel: 'Reject all and close modal',
            //   footer: `
            //               <a href="#path-to-impressum.html" target="_blank">Impressum</a>
            //               <a href="#path-to-privacy-policy.html" target="_blank">Privacy Policy</a>
            //           `,
          },
          preferencesModal: {
            title: 'Výběr cookies',
            acceptAllBtn: 'Chci všechny',
            // acceptNecessaryBtn: 'Jen nezbytné',
            savePreferencesBtn: 'Chci jen vybrané',
            closeIconLabel: 'Zavřít',
            serviceCounterLabel: 'Služba|Služby',
            sections: [
              {
                title: 'Nastavení soukromí',
                description: `Zde můžete nastavit cookies týkající se vašeho soukromí.`,
              },
              {
                title: 'Nezbytné pro fungování',
                description:
                  'Tyto cookies jsou nezbytné pro správné fungování stránky. Bez těch se neobejdete.',

                //this field will generate a toggle linked to the 'necessary' category
                linkedCategory: 'necessary',
                cookieTable: {
                  caption: 'Tabulka cookies',
                  headers: {
                    name: 'Cookie',
                    domain: 'Doména',
                    desc: 'Popis',
                  },
                  body: [
                    {
                      name: 'muxData',
                      domain: location.hostname,
                      desc: 'Díky této cookie vám můžeme přehrávat všechna ta krásná videa.',
                    },
                  ],
                }
              },
              {
                title: 'Výkon a analytika',
                description:
                  'Potřebujeme vědět, kolik lidí nám na web chodí a jak s ním interagují, abychom vám mohli upéct co nejlepší zážitek.',
                linkedCategory: 'analytics',
                cookieTable: {
                  caption: 'Tabulka cookies',
                  headers: {
                    name: 'Cookie',
                    domain: 'Doména',
                    desc: 'Popis',
                  },
                  body: [
                    {
                      name: '_ga',
                      domain: location.hostname,
                      desc: 'Statistické soubory cookies nám pomáhají pochopit, jak se návštěvníci chovají na naší stránce, a to prostřednictvím anonymního shromažďování informací o uživatelích.',
                    },
                  ],
                },
              },
              {
                title: 'Cílení a reklama',
                description:
                  'Marketingové soubory cookies používáme ke sledování pohybu návštěvníků na webových stránkách. Záměrem je zobrazovat reklamy, které jsou relevantní a zajímavé pro jednotlivé uživatele, a tím hodnotnější pro inzerenty třetích stran. Udělením souhlasu uživatel souhlasí se zpracováním a propojováním osobních dat v reklamních systémech za účelem zobrazování cílené reklamy na našich webových stránkách, mimo jiné za pomoci marketingových služeb společnosti Google, Meta Platforms a dalších.',
                linkedCategory: 'ads',
                cookieTable: {
                  caption: 'Tabulka cookies',
                  headers: {
                    name: 'Cookie',
                    domain: 'Doména',
                    desc: 'Popis',
                  },
                  body: [
                    {
                      name: '_gcl',
                      domain: location.hostname,
                      desc: 'Slouží pro lepší cílení reklam na webu. Díky této cookie se vám mohou zobrazovat více relevantní reklamy dle vašich zájmů.',
                    },
                    {
                      name: 'IDE',
                      domain: 'td.doubleclick.net',
                      desc: 'Slouží pro lepší cílení reklam na webu. Díky této cookie se vám mohou zobrazovat více relevantní reklamy dle vašich zájmů.',
                    },
                  ],
                },
              },
              {
                title: 'Více informací',
                description:
                  'Pokud máte jakékoli dotazy, kontaktujte nás prosím přes e-mail, či kontaktní formulář na spodku stránky.',
              },
            ],
          },
        },
      },
    },
  };

  return config;
};

export default getConfig;
