'use client';

import { useLenis } from '@studio-freight/react-lenis';
import React, { ReactElement } from 'react';
import { usePathname, useRouter } from 'next/navigation';

export default function SmoothScroller({ children, href }: { children: React.ReactNode, href: string | number }) {
  const lenis = useLenis();
  const router = useRouter();
  const currentPath = usePathname();

  const navigate = () => {
    const withPage = href.toString().startsWith('/');
    let routerPushed = false;
    if (withPage && currentPath !== href.toString().split('#')[0])
    {
      router.push(href.toString(), { scroll: true });
      return;
    }

    const target = typeof href === 'string' ?
      href.includes('#')
        ? '#' + href.split('#')[1]
        : 0
      : href;

    lenis?.scrollTo(target, { offset: -120 });
  };

  // set onClick to navigate for the children element
  const child = React.Children.only(children);
  if (React.isValidElement(child))
  {
    return React.cloneElement(child as ReactElement, { onClick: () => {child.props.onClick != undefined ? child.props.onClick() : null; navigate()} });
  }
}
