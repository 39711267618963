'use client';

import Image from 'next/image';
import Link from 'next/link';
import SmoothScroller from '@/app/components/smooth-scroller';
import { Button } from '@nextui-org/react';
import { useGSAP } from '@gsap/react';
import React, { useState } from 'react';
import gsap from 'gsap';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

export default function Navbar({}: {}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(null as GSAPTimeline | null);
  const [closeMobileMenu, setCloseMobileMenu] = useState(null as GSAPTimeline | null);

  const mobileMenuContainerRef = React.useRef<HTMLDivElement>(null);

  useGSAP(() => {
    const openMobileMenu = gsap.timeline({ paused: true });
    openMobileMenu.to(mobileMenuContainerRef.current, { display: 'block', duration: 0.03 });
    openMobileMenu.to(mobileMenuContainerRef.current, { opacity: 1, duration: 0.2 });
    openMobileMenu.to('.mobile-menu-toggle div:nth-child(1)', { y: 7, duration: 0.2 }, '<');
    openMobileMenu.to('.mobile-menu-toggle div:nth-child(3)', { y: -7, duration: 0.2 }, '<');
    openMobileMenu.to('.mobile-menu-toggle div:nth-child(2)', { opacity: 0, duration: 0.2 }, '<');
    openMobileMenu.to('.mobile-menu-toggle div:nth-child(1)', { rotate: 45, duration: 0.2 });
    openMobileMenu.to('.mobile-menu-toggle div:nth-child(3)', { rotate: -45, duration: 0.2 }, '<');
    setOpenMobileMenu(openMobileMenu);

    const closeMobileMenu = gsap.timeline({ paused: true });
    closeMobileMenu.to('.mobile-menu-toggle div:nth-child(1)', { rotate: 0, duration: 0.2 });
    closeMobileMenu.to('.mobile-menu-toggle div:nth-child(3)', { rotate: 0, duration: 0.2 }, '<');
    closeMobileMenu.to('.mobile-menu-toggle div:nth-child(1)', { y: 0, duration: 0.2 });
    closeMobileMenu.to('.mobile-menu-toggle div:nth-child(3)', { y: 0, duration: 0.2 }, '<');
    closeMobileMenu.to('.mobile-menu-toggle div:nth-child(2)', { opacity: 1, duration: 0.2 }, '<');
    closeMobileMenu.to(mobileMenuContainerRef.current, { opacity: 0, duration: 0.2 }, '<');
    closeMobileMenu.to(mobileMenuContainerRef.current, { display: 'none', duration: 0.03 });
    setCloseMobileMenu(closeMobileMenu);
  });

  const toggleMobileMenu = () => {
    if (mobileMenuOpen)
    {
      console.log('closing');
      closeMobileMenu?.play(0);
    }
    else
    {
      openMobileMenu?.play(0);
    }
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return <>
    <div className={ 'w-10/12 lg:w-9/12 h-16 md:h-24 bg-primary/10 top-2 2xl:top-4 left-[8.33%] lg:left-[12.5%] fixed flex flex-row items-center z-40 justify-between rounded-2xl navbar backdrop-blur-sm' }>
      <div className={ 'px-8 md:px-16 w-full' }>
        <div className={ 'flex flex-row justify-between w-full items-center' }>
          <SmoothScroller href={ '/' }>
            <div>
              <Image src={ '/logos/logo-b-side-color.svg' } alt={ 'Logo' } width={ 250 } height={ 96 } className={ 'h-24 hidden md:block' } />
              <Image src={ '/logos/logo-b-icon-only-color.svg' } alt={ 'Logo' } width={ 40 } height={ 40 } className={ 'h-10 block md:hidden' } />
            </div>
          </SmoothScroller>
          <div className={ 'flex flex-row justify-center items-center md:justify-end gap-4' }>
            <SmoothScroller href={ '/#co-umime' }>
              <button className={ 'text-md 2xl:text-lg font-normal hidden md:block' }>Co děláme</button>
            </SmoothScroller>
            <SmoothScroller href={ '/#ukazky-prace' }>
              <button className={ 'text-md 2xl:text-lg font-normal hidden md:block' }>Ukázky práce</button>
            </SmoothScroller>
            <SmoothScroller href={ '/#proc-s-nami' }>
              <button className={ 'text-md 2xl:text-lg font-normal hidden 2xl:block' }>Proč s námi</button>
            </SmoothScroller>
            <SmoothScroller href={ '/restomat' }>
              <button className={ 'text-md 2xl:text-lg font-normal hidden md:block' }>Restomat</button>
            </SmoothScroller>
            <Link href={ 'https://blog.gastrostart.cz' } rel={ 'noopener' } target={ '_blank' } className={ 'text-md hidden md:block 2xl:text-lg font-normal' }>Blog</Link>
            <SmoothScroller href={ '/#zaciname' }>
              <Button variant={ 'solid' } size={ 'md' } color={ 'primary' } className={ 'text-md lg:text-lg font-normal ml-2 hidden md:block' }>Mám zájem</Button>
            </SmoothScroller>
            <div className={ 'mobile-menu-toggle  md:hidden flex flex-col gap-1 hover:cursor-pointer' } onClick={ toggleMobileMenu }>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={ 'fixed w-screen h-screen bg-primary/15 z-20 backdrop-blur-md top-0 px-8 opacity-0 hidden' } ref={ mobileMenuContainerRef }>
      <div className={ 'flex flex-col gap-4 items-center justify-between pt-36 pb-36 h-full' }>
        <div className={ 'flex flex-col gap-4 items-center justify-start w-full' }>
          <SmoothScroller href={ '/#co-umime' }>
            <button className={ 'text-4xl font-normal' } onClick={ toggleMobileMenu }>Co děláme</button>
          </SmoothScroller>
          <SmoothScroller href={ '/#ukazky-prace' }>
            <button className={ 'text-4xl font-normal' } onClick={ toggleMobileMenu }>Ukázky práce</button>
          </SmoothScroller>
          <SmoothScroller href={ '/#proc-s-nami' }>
            <button className={ 'text-4xl font-normal' } onClick={ toggleMobileMenu }>Proč s námi</button>
          </SmoothScroller>
          <SmoothScroller href={ '/restomat' }>
            <button className={ 'text-4xl font-normal' } onClick={ toggleMobileMenu }>Restomat</button>
          </SmoothScroller>
          <Link href={ 'https://blog.gastrostart.cz' } rel={ 'noopener' } target={ '_blank' } className={ 'text-4xl font-normal' } onClick={ toggleMobileMenu }>Blog</Link>
        </div>
        <div className={'w-full flex flex-col gap-8'}>
          <SmoothScroller href={ '/#zaciname' }>
            <Button variant={ 'solid' } size={ 'lg' } color={ 'primary' } className={ 'text-lg font-normal mt-8 w-full' } onClick={ toggleMobileMenu }>Mám zájem</Button>
          </SmoothScroller>
          <div className={'flex gap-4 items-center justify-center text-2xl text-black'}>
            <Link href={'https://www.instagram.com/gastrostart.cz/'} target={'_blank'} rel={'noopener noreferrer nofollow'}>
              <FaInstagram size={32} className={'text-black'}></FaInstagram>
            </Link>
            <Link href={'https://www.facebook.com/people/GastroSTART/61554432171140/'} target={'_blank'} rel={'noopener noreferrer nofollow'}>
              <FaFacebook size={32} className={'text-black'}></FaFacebook>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>;
}
