'use client';
import { ReactLenis } from '@studio-freight/react-lenis';
import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

function SmoothScrolling({ children }: { children: React.ReactNode })
{
  const lenisRef = useRef<any>();

  useEffect(() => {
    function update(time: number) {
      lenisRef.current?.lenis?.raf(time * 1000)
    }

    gsap.ticker.add(update)

    return () => {
      gsap.ticker.remove(update)
    }
  })

  return (
    <ReactLenis root options={ { lerp: 0.1, duration: 1.5, smoothWheel: true, syncTouch: false } } ref={lenisRef} autoRaf={false}>
      { children }
    </ReactLenis>
  );
}

export default SmoothScrolling;
